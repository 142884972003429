import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Alert,
  Button, Card, Form, Input, Spin,
} from 'antd';
import { useLocalization } from '@fluent/react';

// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { authStore } from '../stores/auth';
import { getVersionHost } from '../api/version';
import { getVersionCache } from '../api/curvesCache';

const LoginLayout = styled.div`
  max-width: 440px;
  width: 100%;
  margin: auto;
  margin-top: 60px;
`;

const VersionBlock = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: .8rem;
`;

export const LoginPage = observer(() => {
  const [hostVersion, setHostVersion] = useState('');
  const [cacheVersion, setCacheVersion] = useState('');

  const { l10n } = useLocalization();

  // const navigate = useNavigate();
  const onFinish = (values: { username: string; password: string; }) => {
    authStore.login(values);
    // .then(() => {
    //   navigate('/');
    // });
  };

  useEffect(() => {
    getVersionHost()
      .then((x) => setHostVersion(x))
      .catch((e: Error) => setHostVersion(e.name));
    getVersionCache()
      .then((x) => setCacheVersion(`${x.git.branch}/${x.git.commit.id.full.slice(0, 8)}`))
      .catch((e: Error) => setCacheVersion(e.name));
  }, []);

  const alertOpened = axios.isAxiosError(authStore.errors)
    && authStore.errors.response?.status === 400;

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log('Failed:', errorInfo);
  // };

  return (
    <>
      <LoginLayout>
        <Card title={l10n.getString('LoginToApp')} style={{ margin: 20 }}>
          <Form
            name="login"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={l10n.getString('Username')}
              name="username"
              rules={[{
                required: true,
                message: l10n.getString('Login-form-username-field-required'),
              }]}
            >
              <Input autoComplete="username" />
            </Form.Item>

            <Form.Item
              label={l10n.getString('Password')}
              name="password"
              rules={[{
                required: true,
                message: l10n.getString('Login-form-password-field-required'),
              }]}
            >
              <Input.Password autoComplete="current-password" />
            </Form.Item>
            {alertOpened && (
            <Alert
              style={{ marginBottom: 24 }}
              message={l10n.getString('Incorrect-login-or-password')}
              type="error"
            />
            )}
            <Form.Item wrapperCol={{ sm: { offset: 16, span: 8 } }}>
              <Button type="primary" htmlType="submit" block>
                {l10n.getString('Login')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </LoginLayout>
      <VersionBlock>
        Frontend:
        {' '}
        {process.env.REACT_APP_VERSION}
        <br />
        Host .NET:
        {' '}
        {hostVersion || <Spin size="small" />}
        <br />
        Cache JAVA:
        {' '}
        {cacheVersion || <Spin size="small" />}
      </VersionBlock>
    </>
  );
});
