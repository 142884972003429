import { observer } from 'mobx-react-lite';
import {
  Modal, Tooltip, Descriptions, Spin,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import { useEffect, useState } from 'react';
import { getVersionHost } from '../../api/version';
import { getVersionCache } from '../../api/curvesCache';

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const InfoModal = observer(({ visible, onClose }: Props) => {
  const { l10n } = useLocalization();

  const [hostVersion, setHostVersion] = useState('');
  const [cacheVersion, setCacheVersion] = useState('');

  useEffect(() => {
    getVersionHost()
      .then((v) => setHostVersion(v))
      .catch((e: Error) => setHostVersion(e.name));
    getVersionCache()
      .then((v) => setCacheVersion(`${v.build.version}(${v.git.branch}/${v.git.commit.id.full.slice(0, 8)})`))
      .catch((e: Error) => setCacheVersion(e.name));
  }, []);

  return (
    <Modal
      open={visible}
      width={300}
      onCancel={() => onClose()}
      footer={null}
      closeIcon={<Tooltip title={l10n.getString('Close-window')}><CloseOutlined /></Tooltip>}
    >
      <Descriptions title={l10n.getString('About')} column={1}>
        <Descriptions.Item label="Version">{process.env.REACT_APP_VERSION}</Descriptions.Item>
        <Descriptions.Item label="Host .net">{hostVersion || <Spin size="small" />}</Descriptions.Item>
        <Descriptions.Item label="Cache JAVA">{cacheVersion || <Spin size="small" />}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
});
